html:not(.cke_panel_container):not(.new-design) {

background: #f5f5f5;
height: 100%;

font-family:Helvetica-Neue,Helvetica,Arial,sans-serif;line-height:1.5;
font-size:62.5%;

article,aside,audio,body,canvas,caption,details,div,figure,footer,header,hgroup,iframe,img,mark,menu,nav,object,section,span,summary,table,tbody,td,tfoot,thead,tr,video{padding:0;border:0;margin:0}
body{font-family:Helvetica-Neue,Helvetica,Arial,sans-serif;line-height:1.5}
a,abbr,address,b,blockquote,cit,code,dd,del,dfn,dl,dt,em,fieldset,h1,h2,h3,h4,h5,h6,hr,i,ins,label,legend,li,ol,p,pre,q,samp,small,strong,sub,sup,ul{border:0;font-size:100%;vertical-align:baseline;margin:0;padding:0}
article,aside,audio,canvas,figcaption,figure,figure img,footer,header,hgroup,nav,section,video{display:block}
table caption,table td,table th{text-align:left;vertical-align:middle}
a img{border:0}
:focus{outline-width:0}
.flexbox{display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex}

*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
ul{list-style-type:none}

.vrtx-facts-container {
  border: none;
}

.vrtx-facts-container h1,
.vrtx-facts-container h2,
.vrtx-facts-container h3,
.vrtx-facts-container h4,
.vrtx-facts-container h5,
.vrtx-facts-container h6,
.vrtx-facts-container ol,
.vrtx-facts-container p,
.vrtx-facts-container pre,
.vrtx-facts-container ul {
  border: none;
  margin-top: 10px;
  margin-bottom: 5px;
}
.vrtx-facts-container ol,
.vrtx-facts-container ul {
  margin: 15px 0;
  margin-left: 0;
}

figcaption {
  border: none;
  background: none;
  min-height: 0;
  padding: 0;
}

body{color:#444;font-family:Arial,Helvetica,sans-serif;font-size:16px;font-size:1.6rem;line-height:26px;line-height:2.6rem;}
h1,h2,h3,h4,h5,h6{color:#222}
caption,h1,h2,h3{font-weight:400;margin:0 0 10px}
a.bold,h4,h5,h6{font-weight:700}
.menu-label{margin-bottom:5px}
h1{font-size:36px;font-size:3.6rem;line-height:45px;line-height:4.5rem}
h2{font-size:24px;font-size:2.4rem;line-height:34px;line-height:3.4rem}
h4,h5,h6,p{margin:10px 0 5px}
caption,h3{font-size:20px;font-size:2rem;line-height:29px;line-height:2.9rem}
h4,h5,h6{font-size:16px;font-size:1.6rem}
a{color:#2771bb;}

//video { visibility: hidden; }

table,table td,table th{border:1px solid #eaeaea !important; }
table{border-collapse:collapse;border-spacing:0;width:100%}
table caption{margin:0 0 10px}
table td,table th{padding:10px 15px 6px;vertical-align:top}
table th{color:#666;border-width:0 1px 3px 0 !important; }
table td{border-width:0 1px 1px 0 !important; }
table.minimal{border:none}
table.minimal caption{margin-bottom:0}
table.minimal td,table.minimal th{padding-left:0}
table.minimal td:last-child,table.minimal th:last-child{padding-right:0}
table.minimal th{border-width:0 0 3px}
table.minimal td,table.minimal.horizontal th{border-width:0 0 1px}
table.uio-zebra tr:nth-child(odd) td,table.zebra tr:nth-child(odd) td{background:#f6f7f8}
table.fixed-layout,table.table-fixed-layout{table-layout:fixed;word-wrap:break-word}
table.no-border,table.no-border td,table.no-border th,table[border='0'],table[border='0'] td,table[border='0'] th{border:none !important; }
table th[class*=sortable-].forwardSort span,table th[class*=sortable-].reverseSort span{display:inline-block;height:.9em;width:.9em;text-indent:-9999px;overflow:hidden;margin:0 0 0 1ex}
ul,ul li{margin:0}
hr{border-bottom:4px solid #eaeaea}
blockquote{border-left:4px solid #444;padding-left:60px;font-size:18px;font-size:1.8rem;line-height:30px;line-height:3rem;font-family:Georgia,serif}
blockquote:after,blockquote:before{content:" ";display:block;border-left:4px solid #fff;margin-left:-64px;height:1px}
blockquote:before{margin-bottom:-.7ex}
blockquote:after{margin-top:-.7ex}

ol,ul{margin:15px 0}
ol li,ul li{margin:0 10px 5px 3.2ex}
ol li ol,ol li ul,ul li ol,ul li ul{margin:5px 0 10px}
ul>li{position:relative}
ul>li:before{content:"\25A0";color:#666;margin-left:-2.5ex;position:absolute;top:-.15em;left:0;width:1em;overflow:hidden;font-size:.8em}
ul>li.success:before{content:"\2714";color:green;margin-left:-2ex;top:0;font-size:1.2em}
ol ul>li:before,ul ul>li:before{content:"\25A1";color:#666}
ol ul>li.success:before,ul ul>li.success:before{content:"\2714";color:green;margin-left:-2ex;top:0;font-size:1.2em}
ol ol ul>li:before,ol ul ul>li:before,ul ol ul>li:before,ul ul ul>li:before{content:"\2582";color:#666;width:1ex;top:-.4em}
ol ul ul>li.success:before,ul ol ul>li.success:before,ul ul ul>li.success:before{content:"\2714";color:green;margin-left:-2ex;top:0;font-size:1.2em}
blockquote{margin:20px 0}

h2,h3{margin-top:25px}
h1+h2{margin-top:-15px}
h2+h3{margin-top:10px}
dl dl{margin:10px 0}
small{font-size:15px;font-size:1.5rem}
hr,table{margin:20px 0}
hr{clear:left;width:100%}
dt{font-weight:700}
dd{margin:0 0 10px 40px}
code{white-space:normal}
pre{background-color:#f8f8f8;margin:15px 0;padding:15px 15px 15px 16px;white-space:pre;overflow:auto;overflow-y:hidden;border:1px dashed #eaeaea;border-left:6px solid #eaeaea;font-family:monospace,monospace;color:#555;font-size:13px;font-size:1.3rem;line-height:17px;line-height:1.7rem}
pre.shell,pre.shell-green{background:#151515;border:none;color:#eee;padding:16px 16px 16px 21px}
pre.shell-green{color:#4ef84e}
pre code{font-family:inherit;white-space:inherit}
sub,sup{position:relative;font-size:.813em;line-height:0;height:0}
sup{bottom:1ex}
sub{top:.8ex}
abbr.dtend,abbr.dtstart{border-bottom:none;text-decoration:none}
.vrtx-container,.vrtx-image-listing-include-container-description,.vrtx-imagetext,.vrtx-img-container,figcaption{color:#6f6f6f;font-size:15px;font-size:1.5rem;line-height:24px;line-height:2.4rem}
.introduction-div,body.introduction > *,body.introduction.placeholder{font-size:20px;font-size:2rem;line-height:32px;line-height:3.2rem;font-family:Georgia,serif;font-family:Georgia,"Bitstream Charter","Charis SIL",Utopia,"URW Bookman L",serif}

body {
  > ul,
  > ol {
    margin: 20px 0 20px 20px;
  }
}

ul,
ol {
  li {
    margin-right: 0;
  }
}

table a{word-wrap:break-word;word-break:normal;overflow-wrap:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;hyphens:auto}
table td>:first-child,table th>:first-child{margin-top:0}
table td>:last-child,table th>:last-child{margin-bottom:0}
table.table-no-padding{line-height:0;font-size:1px;width:auto}
table.table-no-padding td,table.table-no-padding th{line-height:0;padding:0}
table.retro{font-size:13px;font-size:1.3rem}
table.retro td,table.retro th{padding:5px 5px 5px 10px}
table tfoot{border-top:3px solid #eaeaea}
table tfoot th{text-align:right}
table tfoot td,table tfoot th{border-width:0 1px 1px 0}
table.vertical th{border-width:0 1px 3px 0}
table.vertical thead th[scope=row]{border-width:0 1px 1px 0}
table.vertical tbody th[scope=row]{border-width:0 3px 1px 0;width:1%;white-space:nowrap}
table.vertical.minimal th{border-width:0 0 3px}
table.vertical.minimal thead th[scope=row]{border-width:0}
table.vertical.minimal tbody th[scope=row]{border-width:0 3px 0 0}
table.vertical.minimal tbody th[scope=row]+td,table.vertical.minimal thead th:nth-child(2){padding-left:15px}
table.vertical.minimal td{border-width:0}

body .vrtx-container,
body .vrtx-facts-container,
body iframe{margin:20px 0}
body p.image{margin:0}
body p.image img{margin:20px 0}
body .vrtx-container{margin-bottom:10px}
body .vrtx-container p img{display:block;margin:0 0 10px}
body p.image-p-list{margin:10px 0 5px}
body p.image-p-list img{margin:0}
body p.image-p-list a{display:inline-block;margin-left:5px;vertical-align:top}
body figure{display:table!important;margin:20px 0}
body figcaption{display:table-caption;caption-side:bottom}
body .vrtx-imagetext,body figcaption{margin:10px 0 0}
body .vrtx-imagetext p:first-child,body figcaption p:first-child{margin-top:0}
body .vrtx-imagetext p:last-child,body figcaption p:last-child{margin-bottom:0}
body p.image-center img{display:block!important}
body .align-center,body .image-center figure,body .image-center>img,body p.image-center>img{margin-left:auto;margin-right:auto}
body .align-left,body .image-left,body .left-img,body .vrtx-container-left,body .vrtx-media-left,body .vrtx-media-player.vrtx-container-left,body p img.image-left{margin-top:10px;margin-right:20px;float:left}
body .align-right,body .image-right,body .right-img,body .vrtx-container-right,body .vrtx-introduction-image,body .vrtx-media-player.vrtx-container-right,body .vrtx-media-right,body p img.image-right{margin-top:10px;margin-left:20px;float:right}
body .vrtx-introduction-image,body h2+.align-left,body h2+.align-right,body h2+.image-left,body h2+.image-right,body h2+.left-img,body h2+.right-img,body h2+.vrtx-container-left,body h2+.vrtx-container-right,body h2+.vrtx-media-left,body h2+.vrtx-media-player.vrtx-container-left,body h2+.vrtx-media-player.vrtx-container-right,body h2+.vrtx-media-right,body h2+p img.image-left,body h2+p img.image-right,body h3+.align-left,body h3+.align-right,body h3+.image-left,body h3+.image-right,body h3+.left-img,body h3+.right-img,body h3+.vrtx-container-left,body h3+.vrtx-container-right,body h3+.vrtx-media-left,body h3+.vrtx-media-player.vrtx-container-left,body h3+.vrtx-media-player.vrtx-container-right,body h3+.vrtx-media-right,body h3+p img.image-left,body h3+p img.image-right{margin-top:0}
body [align=left]{margin:0 20px 20px 0}
body [align=right]{margin:0 0 20px 20px}
body .vrtx-facts-container{width:40%;padding:10px 20px;clear:right;background:#eee;margin-bottom:20px}

.image-left figure,
.image-right figure,
.image-center figure,
.image-left > img,
.image-right > img,
.image-center > img {
  margin: 0 0 20px 0;
}

.image-center {
  margin-top: 20px;
}

figure > * {
  vertical-align: bottom;
}

.cke_image_caption_placeholder {
  bottom: -.1em !important;
  left: 0 !important;
}

ul,ol {
  overflow: hidden;
}

.left,.right,.thirds-left,.thirds-middle,.thirds-right{float:left}
.left,.right{width:50%}
.thirds-left,.thirds-middle,.thirds-right{width:33.33333333%}
.left,.right,.thirds-left,.thirds-middle,.thirds-right{margin-bottom:20px}
.left,.thirds-left{margin-left:-12px;padding-right:20px;padding-left:12px;clear:left}
.thirds-middle{padding-left:10px;padding-right:10px;clear:none}
.right,.thirds-right{margin-right:-12px;padding-right:12px;padding-left:20px;clear:none}
.right+*,.thirds-right+*{clear:both}

body.cke_ltr {
  max-width: none;
}

.cke_panel_block h1 {
  line-height: 2;
}

// CK Grid

body {
  margin: 0;
  padding: 12px;
  background-color: #fff;
  min-height: 100% !important;

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  > span:first-child {
    margin-top: 0;

    + * {
      margin-top: 0;
    }
  }
}

body.content         { min-height: 355px !important; }
body.introduction    { min-height: 109px !important; }
body.related-content { min-height: 179px !important; }

body.content,
body.introduction,
body.related-content {
  padding: 0 40px;
  margin: 20px auto;

  border: 1px #D3D3D3 solid;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  > *:first-child {
    margin-top: 20px;
  }
  > *:last-child {
    margin-bottom: 20px;
  }
  > span:first-child {
    margin-top: 0;

    + * {
      margin-top: 20px;
    }
  }

  &.placeholder {
    color: #6f6f6f;
    padding-top: 20px;
  }
}

body.content,
body.introduction,
body.related-content {
  max-width: 660px + 80px + 2px;
}

body.introduction.right-column    { max-width: 507px + 80px + 2px; }
body.content.right-column         { max-width: 507px + 80px + 2px; }
body.related-content.right-column { max-width: 193px + 80px + 2px; }

body.content.not-left-menu,
body.introduction.not-left-menu,
body.related-content.not-left-menu {
  max-width: 970px + 80px + 2px;
}
body.introduction.right-column.not-left-menu    { max-width: 660px + 80px + 2px; }
body.content.right-column.not-left-menu         { max-width: 660px + 80px + 2px; }
body.related-content.right-column.not-left-menu { max-width: 270px + 80px + 2px; }

&.html-content,
&.html-introduction,
&.html-related-content {
  overflow: hidden;
  overflow-y: auto;

  body {
    overflow: hidden;
    overflow-x: auto;
  }

  &.fullwidth {
    overflow: visible;
    overflow-y: visible;
    background-color: #fff;

    body {
      overflow: visible;
      overflow-x: visible;
      max-width: none;
      margin: 0;
      padding: 12px;
      background-color: #fff;
      border: none;
      box-shadow:none;
      min-height: 100% !important;

      > *:first-child {
        margin-top: 0;
      }
      > span:first-child {
        margin-top: 0;

        + * {
          margin-top: 0;
        }
      }
    }
  }
}

}

*::-ms-backdrop, html:not(.cke_panel_container) ul { pointer-events: none; }
*::-ms-backdrop, html:not(.cke_panel_container) ol { pointer-events: none; }

html:not(.cke_panel_container).new-design {

@font-face{
  font-family: TimesNewRomanPSMT;
  src: url(/vrtx/dist/resources/uio2/css/fonts/TimesNewRomanPSMT.woff2) format('woff2'),
    url(/vrtx/dist/resources/uio2/css/fonts/TimesNewRomanPSMT.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

// Reset
body,html{border:0;font-family:Helvetica-Neue,Helvetica,Arial,sans-serif;line-height:1.5;margin:0;padding:0}
article,aside,audio,canvas,caption,details,div,figure,footer,header,hgroup,iframe,img,mark,menu,nav,object,section,span,summary,table,tbody,td,tfoot,thead,tr,video{border:0;margin:0;padding:0}
a,abbr,address,b,blockquote,cit,code,dd,del,dfn,dl,dt,em,fieldset,h1,h2,h3,h4,h5,h6,hr,i,ins,label,legend,li,ol,p,pre,q,samp,small,strong,sub,sup,ul{border:0;font-size:100%;vertical-align:baseline;margin:0;padding:0}
article,aside,audio,canvas,figcaption,figure,figure img,footer,header,hgroup,nav,section,video{display:block}
table caption,table td,table th{text-align:left;vertical-align:middle}
a img{border:0}

figcaption {
  border: none;
  background: none;
  min-height: 0;
  padding: 0;
}
figure {
  width: auto;
}

// Basic things
background: #f5f5f5;
height: 100%;
font-size:62.5%;
--textColor:#000;--linkColor:#2143ef;--borderColor:#000;--publishedDateGrey:#424242;--btnPrimaryBg:#18191c;--btnPrimaryBgHover:#454545;--btnPrimaryColor:#fff;--btnPrimaryColorHover:#fff;--btnSecondaryBg:#b2b3b7;--btnSecondaryBgHover:#757575;--btnSecondaryColor:#000;--btnSecondaryColorHover:#000;--infoBoxDefaultColor:#18191c;--mainFontStack:"Helvetica-Neue",Helvetica,Arial,sans-serif;--secondaryFontStack:'TimesNewRomanPSMT',Georgia,serif;--alternateRowColor:#f4f6ff;

*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
body{font-family:var(--mainFontStack);color:var(--textColor)}
body{font-size:1.8rem;line-height:2.9rem}

ul{list-style-type:none}
ul li{position:relative}
ol,ul{margin:10px 0 40px;overflow:hidden}
ol li>p:last-of-type,ol ol,ol ul,ul li>p:last-of-type,ul ol,ul ul{margin-bottom:0}
ol li,ul li{margin-left:20px}
ol li>p:first-child,ul li>p:first-child{margin-top:0}
li{margin-bottom:10px}
ul li:last-child{margin-bottom:0}
ul li:before{content:'\25A0';margin-left:-20px;position:absolute;text-align:left;font-size:.5em;display:inline-block;width:20px;height:2.7em}
ul li li:before{content:'\25A1'}
ul li li li:before{content:'\25C7'}
ul li.success:before{content:"\2714";color:green;font-size:1.6rem}
dl dl{margin:10px 0}
dt{font-weight:700}
dd{margin:0 0 10px}
blockquote{font-size:4.3rem;line-height:5.2rem;margin:30px 0 50px;font-family:TimesNewRomanPSMT,Georgia,serif}
blockquote>:first-child{margin-top:0}
blockquote>:last-child{margin-bottom:0}
blockquote.blockquote-small{font-size:1.9rem;line-height:2.8rem;border-left:2px solid #000;padding-left:50px}
blockquote.blockquote-small>:first-child{margin-top:10px}
blockquote.blockquote-small>:last-child{margin-bottom:10px}
blockquote.blockquote-small:after,blockquote.blockquote-small:before{content:" ";display:block;border-left:2px solid #fff;margin-left:-52px;height:1px}
hr{width:100%;clear:left;border-bottom:1px solid var(--borderColor);margin:40px 0}
.vrtx-toc-header,blockquote.blockquote-small>:last-child,h2,h3,h4,h5,h6{margin-bottom:10px}
h1,h2,h3,h4,h5,h6{font-weight:400}
h1{font-size:5rem;line-height:6rem;letter-spacing:.2px;margin-bottom:50px}
.vrtx-toc-header,h2{font-size:2.8rem;line-height:3.6rem;letter-spacing:.2px}
.display-as-h3,.vrtx-toc-style-like-h3 .vrtx-toc-header,caption,h3{font-size:2.3rem;line-height:3.2rem;letter-spacing:.2px}
.display-as-h4,.vrtx-toc-style-like-h4 .vrtx-toc-header,h4{font-size:1.9rem;line-height:2.6rem;font-weight:700;letter-spacing:.4px}
h5,h6,small{font-size:1.7rem;line-height:2.7rem;letter-spacing:.2px}
h5{font-weight:700}
h6,small{font-weight:400;font-style:italic}
.vrtx-toc-header,h2{margin-top:60px}
h3{margin-top:30px}
h4,h5{margin-top:25px}
h2+h3,h3+h4,h4+h5{margin-top:0}
p{margin:10px 0 25px}
a{color:var(--textColor);text-decoration:underline}
a:focus,a:hover{color:var(--linkColor)}
a[name]{color:var(--textColor);text-decoration:none}
code{white-space:normal}
pre{background-color:#eceff8;margin:15px 0;padding:15px 15px 15px 16px;white-space:pre;overflow:auto;overflow-y:hidden;border:2px dashed #DBE0F1;border-left:6px solid #DBE0F1;font-family:monospace,monospace;color:#222;font-size:1.4rem;line-height:1.8rem}
pre.shell,pre.shell-green{background:#151515;border:none;color:#eee}
pre.shell-green{color:#2EC483}
pre code{font-family:inherit;white-space:inherit}
sub,sup{position:relative;font-size:.813em;line-height:0;height:0}
sup{bottom:1ex}
sub{top:.8ex}


// Form elements
.search-string-label{position:absolute;left:-9999px;top:-1000px}
input[type=text],input[type=search],input[type=password],input[type=number],input[type=email],input[type=tel],input[type=url]{font-size:2.2rem;line-height:3.1rem;font-family:Arial,Helvetica,sans-serif;letter-spacing: 0.38px;max-width:450px;width:100%;padding:19px 21px 18px;margin:0;background:#f0f1f4;border:1px solid transparent;-webkit-transition:.2s border ease-in-out;-moz-transition:.2s border ease-in-out;-o-transition:.2s border ease-in-out;transition:.2s border ease-in-out;outline:0;-webkit-appearance:none;appearance:none}
input[type=text]:focus,input[type=text]:hover,input[type=search]:focus,input[type=search]:hover,input[type=password]:focus,input[type=password]:hover,input[type=number]:focus,input[type=number]:hover,input[type=email]:focus,input[type=email]:hover,input[type=tel]:focus,input[type=tel]:hover,input[type=url]:focus,input[type=url]:hover{border:1px solid #000}
input[type=text]:invalid,input[type=search]:invalid,input[type=password]:invalid,input[type=number]:invalid,input[type=email]:invalid,input[type=tel]:invalid,input[type=url]:invalid{-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none}
input[type=text]::-webkit-input-placeholder,input[type=search]::-webkit-input-placeholder,input[type=password]::-webkit-input-placeholder,input[type=number]::-webkit-input-placeholder,input[type=email]::-webkit-input-placeholder,input[type=tel]::-webkit-input-placeholder,input[type=url]::-webkit-input-placeholder{color:#222}
input[type=text]:-moz-placeholder,input[type=search]:-moz-placeholder,input[type=password]:-moz-placeholder,input[type=number]:-moz-placeholder,input[type=email]:-moz-placeholder,input[type=tel]:-moz-placeholder,input[type=url]:-moz-placeholder{color:#222}
input[type=text]::-moz-placeholder,input[type=search]::-moz-placeholder,input[type=password]::-moz-placeholder,input[type=number]::-moz-placeholder,input[type=email]::-moz-placeholder,input[type=tel]::-moz-placeholder,input[type=url]::-moz-placeholder{color:#222}
input[type=text]:-ms-input-placeholder,input[type=search]:-ms-input-placeholder,input[type=password]:-ms-input-placeholder,input[type=number]:-ms-input-placeholder,input[type=email]:-ms-input-placeholder,input[type=tel]:-ms-input-placeholder,input[type=url]:-ms-input-placeholder{color:#222}
input[type=text]:focus::-webkit-input-placeholder,input[type=search]:focus::-webkit-input-placeholder,input[type=password]:focus::-webkit-input-placeholder,input[type=number]:focus::-webkit-input-placeholder,input[type=email]:focus::-webkit-input-placeholder,input[type=tel]:focus::-webkit-input-placeholder,input[type=url]:focus::-webkit-input-placeholder{color:#888}
input[type=text]:focus:-moz-placeholder,input[type=search]:focus:-moz-placeholder,input[type=password]:focus:-moz-placeholder,input[type=number]:focus:-moz-placeholder,input[type=email]:focus:-moz-placeholder,input[type=tel]:focus:-moz-placeholder,input[type=url]:focus:-moz-placeholder{color:#888}
input[type=text]:focus::-moz-placeholder,input[type=search]:focus::-moz-placeholder,input[type=password]:focus::-moz-placeholder,input[type=number]:focus::-moz-placeholder,input[type=email]:focus::-moz-placeholder,input[type=tel]:focus::-moz-placeholder,input[type=url]:focus::-moz-placeholder{color:#888}
input[type=text]:focus:-ms-input-placeholder,input[type=search]:focus:-ms-input-placeholder,input[type=password]:focus:-ms-input-placeholder,input[type=number]:focus:-ms-input-placeholder,input[type=email]:focus:-ms-input-placeholder,input[type=tel]:focus:-ms-input-placeholder,input[type=url]:focus:-ms-input-placeholder{color:#888}
.button,.button-large,.button-large-blue,.feedback-no,.feedback-yes,.ui-dialog.ui-widget button,.vrtx-button-larger:not(form),.vrtx-button:not(form),button,input[type=submit],input[type=button],input[type=reset]{font-size:2.2rem;line-height:3.1rem;font-family:Arial,Helvetica,sans-serif;letter-spacing: 0.38px;max-width:450px;width:100%;padding:20px 20px 18px;margin:0;background:var(--btnSecondaryBg);color:var(--btnSecondaryColor);border:none;cursor:pointer;text-decoration:none;outline:0;display:inline-block;-webkit-appearance:none;position:relative}
.button-large-blue::-moz-focus-inner,.button-large::-moz-focus-inner,.button::-moz-focus-inner,.feedback-no::-moz-focus-inner,.feedback-yes::-moz-focus-inner,.ui-dialog.ui-widget button::-moz-focus-inner,.vrtx-button-larger:not(form)::-moz-focus-inner,.vrtx-button:not(form)::-moz-focus-inner,button::-moz-focus-inner,input[type=submit]::-moz-focus-inner,input[type=button]::-moz-focus-inner,input[type=reset]::-moz-focus-inner{padding:0;border:none}
.button-large-blue:focus,.button-large-blue:hover,.button-large:focus,.button-large:hover,.button:focus,.button:hover,.feedback-no:focus,.feedback-no:hover,.feedback-yes:focus,.feedback-yes:hover,.ui-dialog.ui-widget button:focus,.ui-dialog.ui-widget button:hover,.vrtx-button-larger:not(form):focus,.vrtx-button-larger:not(form):hover,.vrtx-button:not(form):focus,.vrtx-button:not(form):hover,button:focus,button:hover,input[type=submit]:focus,input[type=submit]:hover,input[type=button]:focus,input[type=button]:hover,input[type=reset]:focus,input[type=reset]:hover{background:var(--btnSecondaryBgHover);color:var(--btnSecondaryColorHover);text-decoration:none}
.button-large,.button-large-blue,.vrtx-button-larger:not(form){background:var(--btnPrimaryBg);color:var(--btnPrimaryColor);border:none}
.button-large-blue:focus,.button-large-blue:hover,.button-large:focus,.button-large:hover,.vrtx-button-larger:not(form):focus,.vrtx-button-larger:not(form):hover{background:var(--btnPrimaryBgHover);color:var(--btnPrimaryColorHover)}
.vrtx-frontpage-box .button,.vrtx-frontpage-box .button-large,.vrtx-frontpage-box .button-large-blue,.vrtx-frontpage-box .feedback-no,.vrtx-frontpage-box .feedback-yes,.vrtx-frontpage-box .ui-dialog.ui-widget button,.vrtx-frontpage-box .vrtx-button-larger:not(form),.vrtx-frontpage-box .vrtx-button:not(form),.vrtx-frontpage-box button,.vrtx-frontpage-box input[type=submit],.vrtx-frontpage-box input[type=button],.vrtx-frontpage-box input[type=reset]{padding-right:60px;-webkit-transition:.2s padding ease-in-out;-moz-transition:.2s padding ease-in-out;-o-transition:.2s padding ease-in-out;transition:.2s padding ease-in-out}
.vrtx-frontpage-box .button-large-blue:focus,.vrtx-frontpage-box .button-large-blue:hover,.vrtx-frontpage-box .button-large:focus,.vrtx-frontpage-box .button-large:hover,.vrtx-frontpage-box .button:focus,.vrtx-frontpage-box .button:hover,.vrtx-frontpage-box .feedback-no:focus,.vrtx-frontpage-box .feedback-no:hover,.vrtx-frontpage-box .feedback-yes:focus,.vrtx-frontpage-box .feedback-yes:hover,.vrtx-frontpage-box .ui-dialog.ui-widget button:focus,.vrtx-frontpage-box .ui-dialog.ui-widget button:hover,.vrtx-frontpage-box .vrtx-button-larger:not(form):focus,.vrtx-frontpage-box .vrtx-button-larger:not(form):hover,.vrtx-frontpage-box .vrtx-button:not(form):focus,.vrtx-frontpage-box .vrtx-button:not(form):hover,.vrtx-frontpage-box button:focus,.vrtx-frontpage-box button:hover,.vrtx-frontpage-box input[type=submit]:focus,.vrtx-frontpage-box input[type=submit]:hover,.vrtx-frontpage-box input[type=button]:focus,.vrtx-frontpage-box input[type=button]:hover,.vrtx-frontpage-box input[type=reset]:focus,.vrtx-frontpage-box input[type=reset]:hover{padding-left:30px}
.vrtx-frontpage-box .button-large-blue:after,.vrtx-frontpage-box .button-large:after,.vrtx-frontpage-box .button:after,.vrtx-frontpage-box .feedback-no:after,.vrtx-frontpage-box .feedback-yes:after,.vrtx-frontpage-box .ui-dialog.ui-widget button:after,.vrtx-frontpage-box .vrtx-button-larger:not(form):after,.vrtx-frontpage-box .vrtx-button:not(form):after,.vrtx-frontpage-box button:after,.vrtx-frontpage-box input[type=submit]:after,.vrtx-frontpage-box input[type=button]:after,.vrtx-frontpage-box input[type=reset]:after{content:"";background:url(/vrtx/dist/resources/uio2/css/images/arrows/arrow-big.svg) no-repeat;background-size:100% auto;height:1.1em;width:1.1em;position:absolute;top:29px;right:20px}

// Table
table,table td,table th{border:1px solid #000 !important; }
table{border-collapse:collapse;border-spacing:0;width:100%;margin:40px 0}
table a{word-wrap:break-word;word-break:normal;overflow-wrap:break-word;-webkit-hyphens:auto;-moz-hyphens:auto;-ms-hyphens:auto;hyphens:auto}
table caption{margin:0 0 20px}
table td>:last-child,table th>:last-child,table.minimal caption,table.vrtx-person-listing caption,table.vrtx-unit-listing caption{margin-bottom:0}
table td,table th{padding:16px 20px;vertical-align:top;border-width:0 1px 1px 0}
table th{color:#000}
table td>:first-child,table th>:first-child{margin-top:0}
table.minimal{border-width:0}
table.minimal td,table.minimal th{padding-left:0}
table.minimal td:last-child,table.minimal th:last-child{padding-right:0}
table.minimal td,table.minimal th,table.minimal.horizontal th{border-width:0 0 1px}
table.uio-zebra tr:nth-child(odd) td,table.zebra tr:nth-child(odd) td{background:#eceff8}
table.fixed-layout,table.table-fixed-layout{table-layout:fixed;word-wrap:break-word}
table.table-no-padding{line-height:0;font-size:1px;width:auto}
table.table-no-padding td,table.table-no-padding th{line-height:0;padding:0}
table#vacation-table,table.retro{font-size:14px;font-size:1.4rem}
table#vacation-table td,table#vacation-table th,table.retro td,table.retro th{padding:7px}
table.no-border,table.no-border td,table.no-border th,table[border='0'],table[border='0'] td,table[border='0'] th{border:none}
table.vertical th,table.vertical thead th[scope=row]{border-width:0 1px 1px 0}
table.vertical tbody th[scope=row]{border-width:0 1px 1px 0;width:1%;white-space:nowrap}
table.vertical.minimal th{border-width:0 0 1px}
table.vertical.minimal thead th[scope=row]{border-width:0}
table.vertical.minimal tbody th[scope=row]{border-width:0 1px 0 0}
table.vertical.minimal tbody th[scope=row]+td,table.vertical.minimal thead th:nth-child(2){padding-left:15px}
table.vertical.minimal td{border-width:0}

// Images / facts
img:not([src$="spacer.gif"]){max-width:100%;height:auto}

body {
  .vrtx-container-size-xxl{width:800px}
  .vrtx-container-size-xl{width:700px}
  .vrtx-container-size-l{width:600px}
  .vrtx-container-size-m{width:500px}
  .vrtx-container-size-s{width:400px}
  .vrtx-container-size-xs{width:300px}
  .vrtx-container-size-xxs{width:200px}
  .vrtx-container,.vrtx-facts-container{margin:30px 0}
  p.image{margin:0}
  p.image img{margin:30px 0}
  .vrtx-container{margin-bottom:10px}
  .vrtx-container p img{display:block;margin:0 0 10px}
  p.image-p-list{margin:10px 0 5px}
  p.image-p-list img{margin:0}
  p.image-p-list a{display:inline-block;margin-left:5px;vertical-align:top}
  .vrtx-container,.vrtx-image-listing-include-container-description,.vrtx-imagetext,.vrtx-img-container,figcaption{font-size:1.6rem;line-height:2.1rem}
  .vrtx-container p:first-child,.vrtx-image-listing-include-container-description p:first-child,.vrtx-imagetext p:first-child,.vrtx-img-container p:first-child,figcaption p:first-child{margin-top:0}
  .vrtx-container p:last-child,.vrtx-image-listing-include-container-description p:last-child,.vrtx-imagetext p:last-child,.vrtx-img-container p:last-child,figcaption p:last-child{margin-bottom:0}

  figure{display:table!important;margin:30px 0}
  figcaption{display:table-caption;caption-side:bottom}
  .vrtx-imagetext,figcaption{margin:10px 0 0}
  .vrtx-imagetext p:first-child,figcaption p:first-child{margin-top:0}
  .vrtx-imagetext p:last-child,figcaption p:last-child{margin-bottom:0}
  p.image-center img{display:block!important}
  .align-center,.image-center figure,.image-center>img,p.image-center>img{margin-left:auto;margin-right:auto}
  .align-left,.image-left,.left-img,.vrtx-container-left,.vrtx-media-left,.vrtx-media-player.vrtx-container-left,p img.image-left{margin-top:6px;margin-right:30px;margin-bottom:30px;float:left}
  .align-right,.image-right,.right-img,.vrtx-container-right,.vrtx-introduction-image,.vrtx-media-player.vrtx-container-right,.vrtx-media-right,p img.image-right{margin-top:6px;margin-left:30px;margin-bottom:30px;float:right}
  h2+body .align-left,h2+ body .align-right,h2+ body .image-left,h2+ body .image-right,h2+ body .left-img,h2+ body .right-img,h2+ body .vrtx-container-left,h2+ body .vrtx-container-right,h2+ body .vrtx-media-left,h2+ body .vrtx-media-player.vrtx-container-left,h2+ body .vrtx-media-player.vrtx-container-right,h2+ body .vrtx-media-right,h2+ body p img.image-left,h2+ body p img.image-right,h3+ body .align-left,h3+ body .align-right,h3+ body .image-left,h3+ body .image-right,h3+ body .left-img,h3+ body .right-img,h3+ body .vrtx-container-left,h3+ body .vrtx-container-right,h3+ body .vrtx-media-left,h3+ body .vrtx-media-player.vrtx-container-left,h3+ body .vrtx-media-player.vrtx-container-right,h3+ body .vrtx-media-right,h3+ body p img.image-left,h3+ body p img.image-right{margin-top:8px}
  [align=left]{margin:0 30px 30px 0}
  [align=right]{margin:0 0 30px 30px}

  .image-left figure,
  .image-right figure,
  .image-center figure,
  .image-left > img,
  .image-right > img,
  .image-center > img {
    margin: 0 0 30px 0;
  }

  .vrtx-facts-container{width:35%;padding:30px 40px;clear:right;/*background:#18191c;color:#fff;*/margin-bottom:20px}
  .vrtx-facts-container>:first-child{margin-top:0;font-size:2.8rem;line-height:3.6rem;letter-spacing:.4px}
  .vrtx-facts-container a{/*color:#fff;*/text-decoration:underline}
  .vrtx-facts-container a:focus,.vrtx-facts-container a:hover{text-decoration:none}

  .vrtx-facts-container {
    &.info-fullwidth,
    &.info-fullwidth-wide {
      width: 100%;
      float: none;

      >*:first-child img {
        width: 100%;
      }
    }
    &.info-fullwidth-wide {
      margin-left: 0;
      margin-right: 0;
    }
    &.info-fullwidth {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.left,.right,.thirds-left,.thirds-middle,.thirds-right{float:left}
.left,.right{width:50%}
.thirds-left,.thirds-middle,.thirds-right{width:33.33333333%}
.left,.right,.thirds-left,.thirds-middle,.thirds-right{margin-bottom:20px}
.left,.thirds-left{margin-left:-12px;padding-right:40px;padding-left:12px;clear:left}
.right,.thirds-right{margin-right:-12px;padding-right:12px;padding-left:40px;clear:none}
.thirds-left{padding-right:20px}
.thirds-middle{padding-left:10px;padding-right:10px;clear:none}
.thirds-right{padding-left:20px}
.right+*,.thirds-right+*{clear:both}

.cke_image_caption_placeholder {
  bottom: -.1em !important;
  left: 0 !important;
}

body.cke_ltr {
  max-width: none;
}

.cke_panel_block h1 {
  line-height: 2;
}

body.content         { min-height: 355px !important; }
body.introduction    { min-height: 109px !important; }
body.related-content { min-height: 179px !important; }

body.content,
body.introduction,
body.related-content {
  padding: 0 40px;
  margin: 20px auto;

  border: 1px #D3D3D3 solid;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  > *:first-child {
    margin-top: 20px;
  }
  > *:last-child {
    margin-bottom: 20px;
  }
  > span:first-child {
    margin-top: 0;

    + * {
      margin-top: 20px;
    }
  }

  &.placeholder {
    color: #6f6f6f;
    padding-top: 20px;
  }
}

body.introduction {
  max-width: 1040px + 80px + 2px;
  font-size: 3.3rem;
  line-height: 4rem;
  letter-spacing: 0.3px;
}

body.content,
body.related-content {
  max-width: 690px + 80px + 80px + 80px + 2px;

  >*:not(blockquote):not(.info-fullwidth-wide) {
    max-width: 690px;
    &:not(.image-right):not(.image-left):not(.vrtx-container-left):not(.vrtx-container-right) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  >p[style*='margin-left: 40px']  { padding-left: 40px;  }
  >p[style*='margin-left: 80px']  { padding-left: 80px;  }
  >p[style*='margin-left: 120px'] { padding-left: 120px; }
  >p[style*='margin-left: 40px'],
  >p[style*='margin-left: 80px'],
  >p[style*='margin-left: 120px'] {
    margin-left: auto !important;
  }
}

&.html-content,
&.html-introduction,
&.html-related-content {
  overflow: hidden;
  overflow-y: auto;

  body {
    overflow: hidden;
    overflow-x: auto;
  }

  &.fullwidth {
    overflow: visible;
    overflow-y: visible;
    background-color: #fff;

    body {
      overflow: visible;
      overflow-x: visible;
      max-width: none;
      margin: 0;
      padding: 12px;
      background-color: #fff;
      border: none;
      box-shadow:none;
      min-height: 100% !important;

      > *:first-child {
        margin-top: 0;
      }
      > span:first-child {
        margin-top: 0;

        + * {
          margin-top: 0;
        }
      }
    }
  }
}

}
